import React, { useRef } from "react";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { NotiBar } from "./styled";
import PropTypes from "prop-types";

const MobileRequestNotiBar = ({
  uiString,
  openNoti,
  setOpenNoti,
  successSave,
  isComment,
  nameOfVoter
}) => {
  const message = useRef();

  if (successSave && !isComment) {
    message.current = uiString.notibar.save;
  } else if (isComment && nameOfVoter.length < 2) {
    message.current = uiString.notibar.notifications;
  } else if (!isComment) {
    message.current = uiString.notibar.undefinedReason;
  }
  return (
    <>
      {openNoti && message ? (
        <NotiBar
          message={message.current}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
          }}
          open={openNoti}
          onClose={() => setOpenNoti(false)}
          autoHideDuration={2000}
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="secondary"
              onClick={() => setOpenNoti(false)}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

MobileRequestNotiBar.propTypes = {
  openNoti: PropTypes.bool.isRequired,
  setOpenNoti: PropTypes.func.isRequired
};

export default MobileRequestNotiBar;
