import React from "react";
import {
  Container,
  DialogContainer,
  ApplyReasonContainer,
  ApplyTitle,
  ApplyReasonContents,
  Btn,
  ApprovalListContainer,
  ApprovalListTitle,
  ApprovalSwitch,
  Label,
  ApprovalRemarksContainer,
  ApprovalRemarksLabel,
  ApprovalRemarksField
} from "./styled";
import approvalDialogStateManager from "./approvalStateManager";

const PLACEHOLDER_REMAKRS = "결재에 대한 비고를 작성하세요";

const MobileApprovalDialogView = props => {
  const {
    approved,
    rejected,
    remarksComment,
    handleRemarksComment,
    handleApproveCheck
  } = approvalDialogStateManager();

  const {
    data,
    selectedIndex,
    openDialog,
    handleOpenDialog,
    makeApproveDecision,
    setComment
  } = props;

  return (
    <Container openDialog={openDialog}>
      <DialogContainer>
        <ApplyReasonContainer>
          <ApplyTitle>신청 사유</ApplyTitle>
          <ApplyReasonContents>
            {selectedIndex !== -1 && data[selectedIndex].extend_descr}
          </ApplyReasonContents>
        </ApplyReasonContainer>
        <ApprovalListContainer>
          <ApprovalListTitle>결재 항목</ApprovalListTitle>
          <Label
            label="승인"
            labelPlacement="start"
            control={
              <ApprovalSwitch
                onChange={handleApproveCheck("approved", makeApproveDecision)}
                checked={approved}
                value="approved"
              />
            }
          />
          <Label
            label="반려"
            labelPlacement="start"
            control={
              <ApprovalSwitch
                onChange={handleApproveCheck("rejected", makeApproveDecision)}
                checked={rejected}
                value="rejected"
              />
            }
          />
        </ApprovalListContainer>
        <ApprovalRemarksContainer>
          <ApprovalRemarksLabel>결재 비고</ApprovalRemarksLabel>
          <ApprovalRemarksField
            multiline={true}
            placeholder={PLACEHOLDER_REMAKRS}
            value={remarksComment}
            onChange={handleRemarksComment}
          />
        </ApprovalRemarksContainer>
        <Btn onClick={handleOpenDialog(-1, false)}>취소</Btn>
        <Btn
          onClick={() => {
            setComment(remarksComment);
            handleOpenDialog(-1, true);
          }}
        >
          저장
        </Btn>
      </DialogContainer>
    </Container>
  );
};

export default MobileApprovalDialogView;
