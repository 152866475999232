import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import { NotiBar } from "./styled";
import React from "react";
import PropTypes from "prop-types";
import uiString from "../SearchBar/string.json";

const MobileApprovalNotiBar = ({ openNoti, setOpenNoti }) => {
  return (
    <NotiBar
      message={uiString.notifications.notApprovalPersonOrFinished}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center"
      }}
      open={openNoti}
      onClose={() => setOpenNoti(false)}
      autoHideDuration={6000}
      action={[
        <IconButton
          key="close"
          aria-label="close"
          color="secondary"
          onClick={() => setOpenNoti(false)}
        >
          <CloseIcon />
        </IconButton>
      ]}
    />
  );
};

MobileApprovalNotiBar.propTypes = {
  openNoti: PropTypes.bool.isRequired,
  setOpenNoti: PropTypes.func.isRequired
};

export default MobileApprovalNotiBar;
