import { createMuiTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import lightBlue from "@material-ui/core/colors/lightBlue";

const theme = createMuiTheme({
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      // 왼쪽 바 색
      main: "#263238",
      light: "#303C42",
      contrastText: "#FFFFFF"
    },
    secondary: {
      // 타이틀 바 색
      main: "#FF5722",
      contrastText: "#FFFFFF"
    },
    logout: {
      // 왼쪽 바 색
      main: "#fffff"
    },
    action: {
      // 버튼들 외 컨트롤
      selected: "#FF5722",
      hover: "#FF5722"
    }
  },
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: lightBlue.A200
      }
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        // backgroundColor: lightBlue.A200,
        // color: 'white',
      }
    },
    MuiPickersDay: {
      day: {
        color: lightBlue.A700
      },
      isSelected: {
        backgroundColor: lightBlue["400"]
      },
      current: {
        color: lightBlue["900"]
      }
    },
    MuiPickersModal: {
      dialogAction: {
        color: lightBlue["400"]
      }
    },
    MuiInput: {
      underline: {
        "&:before": {
          borderBottom: "0px",
          content: "none"
        },
        "&:after": {
          borderBottom: "0px"
        }
      }
    },
    MuiNativeSelect: {
      select: {
        "&:focus": {
          background: "none"
        }
      }
    }
  }
});

export const RootDiv = styled.div`
  display: flex;
  flex-direction: row;
  height: 100vh;
  width: 100vw;
  /* min-width: 1600px;
  min-height: 900px; */
`;

export const SearchBarDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  max-width: max-content;
`;

export const StyledButton = styled.button`
  outline: none;
  background-color: #4193cc;
  border: none;
  padding: 10px;
  color: white;
  cursor: pointer;
  width: 100px;
  :disabled {
    background-color: #7f8c8d;
    :hover {
      background-color: #7f8c8d;
    }
  }
  :hover {
    background-color: #74b9ff;
  }
  :active {
    transform: scale(0.98);
  }
`;

export default theme;
