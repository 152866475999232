import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

export const Time = styled(TextField)`
  && {
    width: 100%;
    input {
      padding: 5px 10px 2px 10px;
    }
    label {
      color: #8d2222;
      font-weight: bold;
    }
    [type="time"]::-webkit-clear-button {
      display: none;
    }
  }
`;
