// import firebase from "firebase";
import firebase from "firebase/app";
import "firebase/messaging"; // for cloud messaging

export const initializeFirebase = () => {
  firebase.initializeApp({ messagingSenderId: "575260721031" });
  // navigator.serviceWorker
  //   .register("/firebase-messaging-sw.js")
  //   .then(registration => {
  //     firebase.messaging().useServiceWorker(registration);
  //   });
};

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    await messaging.requestPermission();
    const token = await messaging.getToken();

    return token;
  } catch (error) {
    console.error(error);
  }
};
