import React from "react";
import {
  Container,
  CategorySelect,
  Label,
  SearchField,
  SearchContainer
} from "./styled";
import uiString from "./string.json";
const MobileSearchSelection = ({ updateSearchType, updateSearchWord }) => {
  return (
    <Container>
      <Label shrink htmlFor="age-native-label-placeholder">
        검색조건
      </Label>
      <SearchContainer>
        <CategorySelect name="searchType" onChange={updateSearchType}>
          <option value={1}>{uiString.selections.name}</option>
          <option value={2}>{uiString.selections.employeeId}</option>
        </CategorySelect>
        <SearchField
          id="textarea"
          autoFocus
          onChange={updateSearchWord}
          placeholder={uiString.textFields.searchWord}
        />
      </SearchContainer>
    </Container>
  );
};

export default MobileSearchSelection;
