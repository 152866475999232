import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import uiString from "../MobileRequestDialogView/string.json";
import { Btn } from "./styled";

const MobileRequestAlert = ({ alertOpen, handleAlertOpen, handleSave }) => {
  return (
    <Dialog
      open={alertOpen}
      onClose={handleAlertOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {uiString.dialog.title.request}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {uiString.alert.save}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Btn onClick={handleAlertOpen} color="primary">
          취소
        </Btn>
        <Btn onClick={handleSave} color="primary" autoFocus>
          확인
        </Btn>
      </DialogActions>
    </Dialog>
  );
};

export default MobileRequestAlert;
