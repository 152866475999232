import styled from "styled-components";
import Button from "@material-ui/core/Button";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import Snackbar from "@material-ui/core/Snackbar";

export const H3 = styled.h3``;

export const Panel = styled(ExpansionPanel)`
  border-radius: 25px 25px 25px 0 !important;
`;

export const Icon = styled.i`
  margin-right: 10px;
`;

export const Btn = styled(Button)`
  && {
    width: 100px;
    align-self: flex-end;
    background-color: #40a6fb;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  height: 10%;
`;

export const ContentContainer = styled.div`
  display: flex;
  width: 80vw;
  flex-direction: column;
`;

export const TextContainer = styled.div`
  border-bottom: 1px solid black;

  :not(:last-child) {
    margin-bottom: 10px;
  }
`;

export const NotiContainer = styled.div`
  position: absolute;
  bottom: 46px;
  background-color: red;
  width: 200px;
  height: 30px;
`;

export const Notibar = styled(Snackbar)`
  && {
    position: absolute;
    bottom: 46px;
  }
`;
