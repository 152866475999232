import styled from "styled-components";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import MobileStepper from "@material-ui/core/MobileStepper";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import FaceIcon from "@material-ui/icons/Face";

export const ReasonForRequest = styled(TextField)`
  && {
    max-height: 37%;
    border-bottom: 1px solid black;
    overflow: auto;
    margin-top: 10px;
  }
`;

export const SearchApproverContainer = styled.div`
  position: relative;
  width: 100%;
  .bTDFGU.bTDFGU {
    margin: 0;
  }
`;
export const SearchIcon = styled.i`
  color: #e65122;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 50%;
  padding: 5px;
  :active {
    color: white;
    background-color: #0984e3;
  }
`;
export const SearchApprover = styled(TextField)`
  && {
    width: 100%;
    border-bottom: 1px solid black;
    margin-top: 10px;
  }
  input {
    width: 90%;
  }
`;

export const Container = styled.div`
  overflow: auto;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${props => (props.openDialog ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  background-color: #f7f3ee;
`;

export const TimeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid black;
`;

export const TimeDivider = styled.div`
  font-weight: bold;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 3%;
  margin: 0 5% 0 5%;
`;

export const TitleContainer = styled.div`
  font-size: 24px;
  display: flex;
  font-weight: bold;
`;

export const Title = styled.span`
  display: block;
  margin-left: 10px;
  font-size: 20px;
  margin-bottom: 20px;
`;

export const MainContainer = styled.div`
  display: flex;
  justify-self: flex-start;
  margin-bottom: auto;
  flex-direction: column;
  align-content: center;
  padding: 20px 10px 0 10px;
`;

export const BackBtnContainer = styled.div`
  padding: 5px 0 5px 0;
  position: sticky;
  width: 30%;
  top: -60px;
  left: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #0059df;
  font-weight: bold;
  cursor: pointer;
  :active {
    color: #c0392b;
  }
`;

export const GobackBtn = styled(KeyboardArrowLeft)``;

export const NextBtn = styled(KeyboardArrowRight)``;

export const Steppers = styled(MobileStepper)`
  && {
    position: static;
    background-color: transparent;
  }
`;

export const BtnContainer = styled(Button)`
  && {
    font-weight: bold;
    color: #0059df;
  }
`;

export const VoterListsContainer = styled.div`
  overflow: auto;
  margin-top: 15px;
  width: 100%;
  height: 65%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const VoterItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px;
  width: 70%;
  padding: 2px 10px 2px 10px;
  :not(:last-child) {
    margin-bottom: 7px;
  }
  background-color: white;
  border: 2px solid #55bf8c;
  :hover {
    background-color: #000e36;
    border: 2px solid #31abfb;
    color: white;
  }
`;

export const VoterName = styled.h3`
  margin: 0;
  font-size: 13px;
`;

export const PlusIcon = styled.i`
  color: #55bf8c;
  :active {
    transform: scale(0.9);
    color: white;
  }
`;

export const SelectedVoterContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
`;

export const SelectedVoterTitleContainer = styled.div`
  display: flex;
  align-items: center;
  align-self: start;
  margin-bottom: 10px;
`;

export const SelectedVoterIcon = styled(FaceIcon)`
  && {
    font-size: 30px;
  }
`;

export const SelectedVoterTitle = styled.h1`
  font-size: 20px;
  margin: 0;
`;

export const MinusIcon = styled.i`
  margin-right: 10px;
  color: #55bf8c;
  :active {
    transform: scale(0.9);
    color: white;
  }
`;

export const UpAndDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px;
`;

export const UpAndDownIcon = styled.i`
  :last-child {
    margin-top: 10%;
  }
  :active {
    color: #a29bfe;
  }
`;

export const SubmitBtn = styled.div`
  position: fixed;
  color: white;
  background-color: #e65122;
  border-radius: 20px;
  bottom: 8%;
  font-weight: bold;
  padding: 8px 40px 8px 40px;
  right: 6%;
  :active {
    background-color: #2d3436;
    transform: scale(0.95);
  }
`;
