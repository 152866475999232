import * as React from "react";
import moment from "moment";
import RemainTimeInformation from "./remain-time-information";
import {
  Container,
  TitleContainer,
  TitleSection,
  Title,
  TitleContentSection,
  TitleContent,
  DescriptionContainer,
  ChartContainer
} from "./styled";
import WorkingInformationChart from "./working-information-chart";
import { useLocation } from "react-router-dom";

type DataSets = {
  backgroundColor: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  data: number[];
  label: string;
};

type Props = {
  title: string;
  titleColor: string;
  titleContentColor: string;
  titleContentBackgroundColor: string;
  workingTime: number;
  maxWorkingTime: number;
  regularRemainingWorkingTime: number;
  overWorkingTime: number;
  overRemainingWorkingTime: number;
  datasets: DataSets[];
  isToday: boolean;
};

const WorkingInformationBox = <P extends Props>({
  title,
  titleColor,
  workingTime,
  maxWorkingTime,
  overWorkingTime,
  titleContentColor,
  titleContentBackgroundColor,
  datasets,
  isToday
}: P) => {
  const isPc = useLocation().pathname.split("/")[1] !== "m" ? true : false;
  const hours = React.useMemo(() => {
    return Math.floor(moment.duration(workingTime, "minutes").asHours());
  }, [workingTime]);
  const minutes = React.useMemo(() => {
    return workingTime - hours * 60;
  }, [workingTime, hours]);
  return (
    <Container data-testid="working-information-box">
      <TitleContainer>
        <TitleSection>
          <Title
            data-testid="working-information-box-title"
            titleColor={titleColor}
          >
            {title}
          </Title>
          <TitleContentSection
            titleContentBackgroundColor={titleContentBackgroundColor}
            titleContentColor={titleContentColor}
          >
            <TitleContent data-testid="working-information-box-title-content">
              {workingTime ? `${hours}시간 ${minutes}분` : "시간"}
            </TitleContent>
          </TitleContentSection>
        </TitleSection>
      </TitleContainer>
      <DescriptionContainer>
        <RemainTimeInformation
          regular={{
            color: datasets[0]?.backgroundColor,
            minutes: maxWorkingTime
          }}
          extension={{
            color: datasets[1]?.backgroundColor,
            minutes: overWorkingTime
          }}
        />
      </DescriptionContainer>
      {isPc && (
        <ChartContainer>
          <WorkingInformationChart
            labels={[title]}
            datasets={datasets}
            isToday={isToday}
          />
        </ChartContainer>
      )}
    </Container>
  );
};

export default WorkingInformationBox;
