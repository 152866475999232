import React from "react";
import {
  H3,
  Panel,
  Icon,
  Btn,
  ContentContainer,
  TextContainer
} from "./styled";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import TextField from "@material-ui/core/TextField";
import password, { passwordNotibar } from "../password";
const SettingForm = ({ props }) => {
  const { signInReducer } = props;
  const {
    currentPassword,
    newPassword,
    reEnteredNewPassword,
    notiMismatchOpen,
    setNotiMismatchOpen,
    notiWrongPasswordOpen,
    setNotiWrongPasswordOpen,
    notiChangedPasswordOpen,
    setNotiChangedPasswordOpen,
    handleChange,
    saveThePassword
  } = password(signInReducer);
  const CURRENT_PASSWORD = 1;
  const NEW_PASSWORD = 2;
  const RE_ENTER_NEW_PASSWORD = 3;

  return (
    <React.Fragment>
      <Panel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <H3>
            <Icon className="fas fa-lock" />
            패스워드 변경
          </H3>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <ContentContainer>
            <TextContainer>
              <TextField
                value={currentPassword}
                onChange={handleChange(CURRENT_PASSWORD)}
                fullWidth={true}
                placeholder="현재 패스워드를 입력하세요"
                label="현재 패스워드"
                type="password"
              />
            </TextContainer>
            <TextContainer>
              <TextField
                value={newPassword}
                onChange={handleChange(NEW_PASSWORD)}
                fullWidth={true}
                placeholder="변경할 패스워드를 입력하세요"
                label="새로운 패스워드"
                type="password"
              />
            </TextContainer>
            <TextContainer>
              <TextField
                value={reEnteredNewPassword}
                onChange={handleChange(RE_ENTER_NEW_PASSWORD)}
                fullWidth={true}
                placeholder="변경할 패스워드를 한번더 입력하세요"
                label="새로운 패스워드 확인"
                type="password"
              />
            </TextContainer>
            <Btn
              variant="contained"
              color="primary"
              type="submit"
              size="medium"
              onClick={saveThePassword}
            >
              변경 하기
            </Btn>
          </ContentContainer>
        </ExpansionPanelDetails>
      </Panel>
      {passwordNotibar({
        notiMismatchOpen,
        setNotiMismatchOpen,
        notiWrongPasswordOpen,
        setNotiWrongPasswordOpen,
        notiChangedPasswordOpen,
        setNotiChangedPasswordOpen
      })}
    </React.Fragment>
  );
};

export default SettingForm;
