import Auth from "../../../Auth/Auth";
import { WEB_SERVER_ADDR } from "../../../constValues";
import axios from "axios";

const moveToPage = (history, signInReducer, setUserInfo) => {
  if (signInReducer.name === "" || signInReducer.employee_id === "") {
    const token = Auth.getUserToken();
    if (!token) {
      history.push("/");
      return;
    }
    let restApiUri = `${WEB_SERVER_ADDR}/api/mainview`;
    axios
      .get(restApiUri, {
        headers: {
          token: Auth.getUserToken()
        }
      })
      .then(response => {
        if (response.data) {
          // this._setFcmToken(response.data.employee_id);
          setUserInfo(response.data);
        }
      })
      .catch(e => {
        history.push("/");
      });
    return;
  }
};

export default moveToPage;
