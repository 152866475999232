import * as React from "react";
import WorkingInformationBox from "../working-information-box";
import colors from "../../../../../style-sheet/colors";
import {
  TodayWorkingInformationBoxContainer,
  WeekWorkingInformationBoxContainer
} from "./styled";
import useTodayWorkingInformationBox from "../../../../../hooks/personal-work-dashboard-page-hooks/use-today-working-information-box";
import useWorkingInformationChart from "../../../../../hooks/personal-work-dashboard-page-hooks/use-working-information-chart";
import useWeekWorkingInformationBox from "../../../../../hooks/personal-work-dashboard-page-hooks/use-week-working-information-box";

type Props = {
  userId: string;
};

const WorkinginformationBoxContainer = <P extends Props>({ userId }: P) => {
  const todayWorkingInformationBox = useTodayWorkingInformationBox({ userId });
  const weekWorkingInformationBox = useWeekWorkingInformationBox({ userId });
  const { datasets: todayDatsets } = useWorkingInformationChart({
    isToday: true,
    workingTime: todayWorkingInformationBox.workingTime,
    maxWorkingTime: todayWorkingInformationBox.maxWorkingTime,
    maxOverWorkingTime: todayWorkingInformationBox.overWorkingTime,
    remainWorkingTime: todayWorkingInformationBox.regularRemainingWorkingTime,
    overRemainingWorkingTime:
      todayWorkingInformationBox.overRemainingWorkingTime,
    overWorkingTimeColor:
      colors.personalDashBoard.todayWorkingHoursBox.extendTimeColor,
    WorkingTimeColor:
      colors.personalDashBoard.todayWorkingHoursBox.regularTimeColor
  });

  const { datasets: weekDatasets } = useWorkingInformationChart({
    isToday: false,
    workingTime: weekWorkingInformationBox.workingTime,
    maxWorkingTime: weekWorkingInformationBox.maxWorkingTime,
    maxOverWorkingTime: weekWorkingInformationBox.overWorkingTime,
    remainWorkingTime: weekWorkingInformationBox.regularRemainingWorkingTime,
    overRemainingWorkingTime:
      weekWorkingInformationBox.overRemainingWorkingTime,
    overWorkingTimeColor:
      colors.personalDashBoard.weekWorkingHoursBox.extendTimeColor,
    WorkingTimeColor:
      colors.personalDashBoard.weekWorkingHoursBox.regularTimeColor
  });

  return (
    <React.Fragment>
      <TodayWorkingInformationBoxContainer>
        <WorkingInformationBox
          isToday={true}
          {...todayWorkingInformationBox}
          title="금일 근무시간"
          titleColor={colors.personalDashBoard.todayWorkingHoursBox.title}
          titleContentColor={
            colors.personalDashBoard.todayWorkingHoursBox.timeColor
          }
          titleContentBackgroundColor={
            colors.personalDashBoard.todayWorkingHoursBox.timeBackgroundColor
          }
          datasets={todayDatsets}
        />
      </TodayWorkingInformationBoxContainer>
      <WeekWorkingInformationBoxContainer>
        <WorkingInformationBox
          isToday={false}
          {...weekWorkingInformationBox}
          title="금주 근무시간"
          titleColor={colors.personalDashBoard.weekWorkingHoursBox.title}
          titleContentColor={
            colors.personalDashBoard.weekWorkingHoursBox.timeColor
          }
          titleContentBackgroundColor={
            colors.personalDashBoard.weekWorkingHoursBox.timeBackgroundColor
          }
          datasets={weekDatasets}
        />
      </WeekWorkingInformationBoxContainer>
    </React.Fragment>
  );
};

export default WorkinginformationBoxContainer;
