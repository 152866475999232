import React, { useState } from "react";
import { Date } from "./styled";
import moment from "moment";
const RequestDate = ({ label, startDate, endDate, isStart, onChange }) => {
  const [start, setStart] = useState(
    startDate
      ? moment(startDate).format("YYYY[-]MM[-]DD")
      : moment()
          .subtract(7, "days")
          .format("YYYY[-]MM[-]DD")
  );
  const [end, setEnd] = useState(
    endDate
      ? moment(endDate).format("YYYY[-]MM[-]DD")
      : moment().format("YYYY[-]MM[-]DD")
  );

  const updateDate = event => {
    if (isStart) {
      setStart(event.target.value);
    } else {
      setEnd(event.target.value);
    }
    onChange(event);
  };
  return (
    <React.Fragment>
      <Date
        onChange={updateDate}
        defaultValue={isStart ? start : end}
        label={label}
        type="date"
      />
    </React.Fragment>
  );
};

export default RequestDate;
