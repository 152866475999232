import React, { useState } from "react";
import { WEB_SERVER_ADDR } from "../../../../constValues";
import jwt from "jsonwebtoken";
import settings from "../../../../config/settings";
import axios from "axios";
import Auth from "../../../../Auth/Auth";
import uiString from "./string.json";
import { Notibar } from "../SettingFrom/styled";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const Password = signInReducer => {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [reEnteredNewPassword, setReEnteredNewPassword] = useState("");
  const [notiMismatchOpen, setNotiMismatchOpen] = useState(false);
  const [notiWrongPasswordOpen, setNotiWrongPasswordOpen] = useState(false);
  const [notiChangedPasswordOpen, setNotiChangedPasswordOpen] = useState(false);

  const handleChange = id => event => {
    if (id === 1) {
      setCurrentPassword(event.target.value);
    } else if (id === 2) {
      setNewPassword(event.target.value);
    } else if (id === 3) {
      setReEnteredNewPassword(event.target.value);
    }
  };

  const saveThePassword = async () => {
    if (newPassword !== reEnteredNewPassword) {
      setNotiMismatchOpen(true);
      return;
    }

    let restApiUrl = `${WEB_SERVER_ADDR}/api/settings/Password`;

    let secretToken = jwt.sign(
      {
        userId: signInReducer.employee_id,
        currentPassword,
        newPassword
      },
      settings.secret
    );

    try {
      const ret = await axios.post(
        restApiUrl,
        {
          secretToken
        },
        {
          headers: {
            token: Auth.getUserToken()
          }
        }
      );
      if (ret.status !== 200) {
        setNotiWrongPasswordOpen(true);
      } else {
        setNotiChangedPasswordOpen(true);
      }
    } catch (error) {
      setNotiWrongPasswordOpen(true);
    }
  };

  return {
    currentPassword,
    setCurrentPassword,
    newPassword,
    setNewPassword,
    reEnteredNewPassword,
    setReEnteredNewPassword,
    notiMismatchOpen,
    setNotiMismatchOpen,
    notiWrongPasswordOpen,
    setNotiWrongPasswordOpen,
    notiChangedPasswordOpen,
    setNotiChangedPasswordOpen,
    handleChange,
    saveThePassword
  };
};

const hideDownSignNoti = set => {
  set(false);
};

const createMessage = (check, list) => {
  if (check === list.notiMismatchOpen) {
    return <span>{uiString.notiBars.misMatchPassword}</span>;
  } else if (check === list.notiWrongPasswordOpen) {
    return <span>{uiString.notiBars.wrongPassword}</span>;
  } else if (check === list.notiChangedPasswordOpen) {
    return <span>{uiString.notiBars.changePasswordDone}</span>;
  }
};
export const passwordNotibar = ({
  notiMismatchOpen,
  setNotiMismatchOpen,
  notiWrongPasswordOpen,
  setNotiWrongPasswordOpen,
  notiChangedPasswordOpen,
  setNotiChangedPasswordOpen
}) => {
  if (notiMismatchOpen) {
    return (
      <Notibar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={true}
        autoHideDuration={6000}
        onClose={() => hideDownSignNoti(setNotiMismatchOpen)}
        message={createMessage(notiMismatchOpen, {
          notiMismatchOpen,
          notiWrongPasswordOpen,
          notiChangedPasswordOpen
        })}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="secondary"
            onClick={() => hideDownSignNoti(setNotiMismatchOpen)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  } else if (notiWrongPasswordOpen) {
    return (
      <Notibar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={true}
        autoHideDuration={6000}
        onClose={() => hideDownSignNoti(setNotiWrongPasswordOpen)}
        message={createMessage(notiWrongPasswordOpen, {
          notiMismatchOpen,
          notiWrongPasswordOpen,
          notiChangedPasswordOpen
        })}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="secondary"
            onClick={() => hideDownSignNoti(setNotiWrongPasswordOpen)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  } else if (notiChangedPasswordOpen) {
    return (
      <Notibar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        open={true}
        autoHideDuration={6000}
        onClose={() => hideDownSignNoti(setNotiChangedPasswordOpen)}
        message={createMessage(notiChangedPasswordOpen, {
          notiMismatchOpen,
          notiWrongPasswordOpen,
          notiChangedPasswordOpen
        })}
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="secondary"
            onClick={() => hideDownSignNoti(setNotiChangedPasswordOpen)}
          >
            <CloseIcon />
          </IconButton>
        ]}
      />
    );
  }
};

export default Password;
