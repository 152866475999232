import { useState } from "react";
import moment from "moment";
import {
  ENTIRE_CATEGORY,
  ENTIRE_APPROVE_CATEGORY
} from "../../../../constValues";
const MobileRequestSearchManager = () => {
  const [openNoti, setOpenNoti] = useState(false);
  const [lastApproverId, setLastApproverId] = useState(null);
  const [searchWord, setSearchWord] = useState("");
  const [tableKey, setTableKey] = useState("firstKey");
  const [startDate, setStartDate] = useState(
    moment()
      .subtract(7, "days")
      .toDate()
  );
  const [endDate, setEndDate] = useState(moment().toDate());
  const [categoryId, setCategoryId] = useState(ENTIRE_CATEGORY);
  const [requestResult, setRequestResult] = useState(ENTIRE_APPROVE_CATEGORY);
  const [openDialog, setOpenDialog] = useState(false);
  const [save, setSave] = useState(false);

  const changeStartDate = event => {
    if (event.target) {
      setStartDate(moment(event.target.value).toDate());
    } else {
      setStartDate(moment(event).toDate());
    }
  };
  const changeEndDate = event => {
    if (event.target) {
      setEndDate(moment(event.target.value).toDate());
    } else {
      setEndDate(moment(event).toDate());
    }
  };

  const updateCategoryId = id => {
    setCategoryId(id);
  };

  const updateRequestResult = value => {
    setRequestResult(value);
  };

  const updateSearchWord = event => {
    setSearchWord(event.target.value);
  };

  const handleOpenDialog = () => {
    if (!openDialog) {
      setOpenDialog(true);
    } else {
      setOpenDialog(false);
    }
  };

  const dialogResult = (updateApprovalDoc, _getApprovalSearchDocs) => {
    updateApprovalDoc(lastApproverId);
    setTimeout(() => {
      _getApprovalSearchDocs();
    }, 300);
    setLastApproverId(null);
  };

  return {
    searchWord,
    updateSearchWord,
    tableKey,
    setTableKey,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    categoryId,
    setCategoryId,
    requestResult,
    setRequestResult,
    changeStartDate,
    changeEndDate,
    updateCategoryId,
    updateRequestResult,
    openDialog,
    handleOpenDialog,
    lastApproverId,
    setLastApproverId,
    dialogResult,
    save,
    setSave,
    openNoti,
    setOpenNoti
  };
};

export default MobileRequestSearchManager;
