import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";

export const CategorySelect = styled(NativeSelect)`
  && {
    padding: 0 20px 0 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid black;
  }
`;

export const Label = styled(InputLabel)`
  && {
    color: #8d2222;
    font-weight: bold;
  }
`;
