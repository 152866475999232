import api from "../api";

const SETTINGS = `${api.api}/settings`;
const PHONE_NUMBERS = `${SETTINGS}/phone_numbers`;
const PHONE_NUMBERS_USER = (employeeid: string) =>
  `${SETTINGS}/phone_numbers/${employeeid}`;
const PASSWORD = `${SETTINGS}/password`;
const UPLOAD_PROFILE_USER = `${SETTINGS}/upload/profile/:employeeid`;
const DELEGATER = `${SETTINGS}/delegater`;
const SMTP = `${SETTINGS}/smtp`;
const SMTP_USER = `${SETTINGS}/smtp/:employeeid`;
const SEARCH_EMPLOYEE = `${SETTINGS}/searchemployee`;
const SEARCH_DEPARTMENT = `${SETTINGS}/searchdepartment`;
const EMAIL = `${SETTINGS}/email`;
const CLIENT_LIST = `${SETTINGS}/cclist`;
const WORKING_TEMPLATE = `${SETTINGS}/workingtemplate`;
const EMPLOYEES_WORKING_TEMPLATE = `${SETTINGS}/employees/workingtemplate`;
const DEPARTMENTS_WORKING_TEMPLATE = `${SETTINGS}/departments/workingtemplate`;
const ENDPOINTS_WORKING_TEMPLATE = `${SETTINGS}/endpoints/workingtemplate`;
const CUSTOM_STATISTICS_FIELD = `${SETTINGS}/customstatisticsfield`;
const MENU_SET = `${SETTINGS}/menuset`;
const ALARM_EVENT = `${SETTINGS}/alarm-event`;
const ALARM_EVENT_STATUS = `${ALARM_EVENT}/status`;
const PUBLIC_DEVICE = `${SETTINGS}/public-device`;
const INIT_PASSWORD = `${SETTINGS}/password`;

const settingsApi = {
  phoneNumbers: PHONE_NUMBERS,
  phoneNumbersUser: PHONE_NUMBERS_USER,
  password: PASSWORD,
  uploadProfileUser: UPLOAD_PROFILE_USER,
  delegater: DELEGATER,
  smtp: SMTP,
  smtpUser: SMTP_USER,
  searchEmployee: SEARCH_EMPLOYEE,
  searchDepartment: SEARCH_DEPARTMENT,
  email: EMAIL,
  clientList: CLIENT_LIST,
  workingTemplate: WORKING_TEMPLATE,
  employeesWorkingTemplate: EMPLOYEES_WORKING_TEMPLATE,
  departmentsWorkingTemplate: DEPARTMENTS_WORKING_TEMPLATE,
  endpointsWorkingTemplate: ENDPOINTS_WORKING_TEMPLATE,
  customStatisticsfield: CUSTOM_STATISTICS_FIELD,
  menuSet: MENU_SET,
  alarmEvent: ALARM_EVENT,
  alarmEventStatus: ALARM_EVENT_STATUS,
  publicDevice: PUBLIC_DEVICE,
  initPassword: INIT_PASSWORD
};

export default settingsApi;
