import styled from "styled-components";
import Snackbar from "@material-ui/core/Snackbar";

export const NotiBar = styled(Snackbar)`
  && {
    align-self: flex-end;
    /* position: fixed;
    bottom: 46px; */
  }
`;
