import * as React from "react";
import { HorizontalBar } from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import moment from "moment";

type DataSets = {
  backgroundColor: string;
  barThickness: number;
  maxBarThickness: number;
  minBarLength: number;
  data: number[];
  label: string;
};

type Props = {
  labels: string[];
  datasets: DataSets[];
  isToday: boolean;
};

const WorkingInformationChart = <P extends Props>({
  labels,
  datasets,
  isToday
}: P) => {
  const ref = React.useRef<any>(null);

  React.useEffect(() => {
    ref.current?.chartInstance.update();
  }, [datasets]);
  return (
    <HorizontalBar
      ref={ref}
      data={{ labels, datasets }}
      width={10}
      height={20}
      options={{
        plugins: {
          datalabels: {
            formatter: function (value: any, context: any) {
              if (context.datasetIndex !== 2) {
                const hours = Math.floor(
                  moment.duration(value, "minutes").asHours()
                );
                const min = value % 60;
                return `${hours}h ${min}m`;
              } else {
                return "";
              }
            },
            display: true,
            align: "center",
            anchor: "center",
            color: "white"
          }
        },
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        },
        scales: {
          yAxes: [
            {
              display: false,
              gridLines: {},
              stacked: true
            }
          ],
          xAxes: [
            {
              ticks: {
                stepSize: isToday ? 240 : 120,
                beginAtZero: true,
                callback: function (value: number) {
                  if (value < 0) {
                    return `0`;
                  }
                  const hours = Math.floor(
                    moment.duration(value, "minutes").asHours()
                  );
                  return `${hours}`;
                }
              },
              stacked: true
            }
          ]
        }
      }}
    />
  );
};

export default WorkingInformationChart;
