import React from "react";
import MobileApprovalContainer from "../../../moContainer/main-view/MobileApprovalContainer";
import MobileSummaryContainer from "../../../moContainer/main-view/MobileSummaryContainer";
import MobileRequestContainer from "../../../moContainer/main-view/MobileRequestContainer";
import MobileSettingContainer from "../../../moContainer/main-view/MobileSettingContainer";
import items from "../NavBar/items";

const containerSelector = pathName => {
  switch (pathName) {
    case items.home.path:
      return <MobileSummaryContainer />;
    case items.request.path:
      return <MobileRequestContainer />;
    case items.approval.path:
      return <MobileApprovalContainer />;
    case items.settings.path:
      return <MobileSettingContainer />;
    default:
  }
};

export default containerSelector;
