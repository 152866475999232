import React from "react";
import { Time } from "./styled";
import moment from "moment";
const MobileRequestTime = ({
  label,
  startTime,
  endTime,
  isStart,
  onChange
}) => {
  const updateDate = event => {
    onChange(event);
  };
  return (
    <React.Fragment>
      <Time
        id="time"
        onChange={updateDate}
        defaultValue={
          isStart
            ? `${
                typeof startTime === "object"
                  ? moment(startTime).format("HH:mm")
                  : startTime
              }`
            : `${
                typeof endTime === "object"
                  ? moment(endTime).format("HH:mm")
                  : endTime
              }`
        }
        label={label}
        type="time"
      />
    </React.Fragment>
  );
};

export default MobileRequestTime;
