import React, { Suspense, lazy } from "react";
import { ThemeProvider } from "styled-components";
import { MuiThemeProvider } from "@material-ui/core/styles";
import theme, { RootDiv } from "./custom-theme";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { MuiPickersUtilsProvider } from "material-ui-pickers";
import MomentUtils from "@date-io/moment";
import { MobileMainViewContainer } from "./moContainer";
import routes from "./routes";

const ElectronMainPage = React.lazy(() => import("./components/electron"));

const ChartContainer = React.lazy(
  () => import("./components/main-view/ChartContainer")
);
const MainViewContainer = lazy(
  () => import("./containers/main-view/MainViewContainer")
);
// const ScreenShareContainer = lazy(() =>
//   import("./containers/WebRTC/ScreenShareContainer/ScreenShareContainer")
// );
const SignInContainer = lazy(
  () => import("./containers/sign-in/SignInContainer")
);
const RommsContainer = lazy(() => import("./containers/WebRTC/RoomsContainer"));
// DatePicker에서 달력이나 시간 ui를 custom하기위한 function
class LocalizedUtils extends MomentUtils {
  //   getMeridiemText(ampm) {
  //     return Info.meridiems({ locale: this.locale }).find(v => {
  //       if (ampm === "am" && v === "오전") return true;
  //       if (ampm === "pm" && v === "오후") return true;
  //       return false;
  //     });
  //   }
  // getCalendarHeaderText(date) {
  //   return date.format("YYYY년 MM월");
  // }
}

const App = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={LocalizedUtils}>
          <RootDiv>
            <Suspense fallback={<div>Loading...</div>}>
              <BrowserRouter>
                <Switch>
                  {/* <Route
                    path={`${
                      routes.pageRoutes.main
                    }${routes.pageRoutes.room()}`}
                    component={ScreenShareContainer}
                  /> */}
                  <Route
                    path={routes.pageRoutes.electron}
                    component={ElectronMainPage}
                  />
                  <Route
                    path={`${routes.pageRoutes.main}${routes.pageRoutes.chart}`}
                    component={ChartContainer}
                  />
                  <Route
                    path={`${routes.pageRoutes.main}${routes.pageRoutes.rtc}`}
                    component={RommsContainer}
                    exact
                  />
                  <Route
                    path={routes.pageRoutes.main}
                    component={MainViewContainer}
                  />
                  <Route
                    path={routes.pageRoutes.noAuth}
                    component={SignInContainer}
                  />
                  <Route
                    path={`/m${routes.pageRoutes.main}`}
                    component={MobileMainViewContainer}
                  />
                  <Route
                    exact
                    path={routes.pageRoutes.root}
                    component={SignInContainer}
                  />
                </Switch>
              </BrowserRouter>
            </Suspense>
          </RootDiv>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  );
};

export default App;
