import React, { useEffect } from "react";
import { getCurrentApprover } from "../../../../Utils/commonAxiosCall";
import MobileCategorySelectionContainer from "../../../../moContainer/main-view/mobile-category-selection-container";
import MobileContentsContainer from "../../../../moContainer/main-view/MobileContentsContainer.js";
import MobileApprovalDialogContainer from "../../../../moContainer/main-view/MobileApprobalDialogContainer";
import {
  RootDiv,
  Container,
  Btn,
  BtnContainer
} from "../../MobileApprovalView/mobile-approval-searchbar/styled";
import searchStateManager from "../../MobileApprovalView/mobile-approval-searchbar/searchStateManager";
import SearchIcon from "@material-ui/icons/Search";
import MobileSearchSelection from "../../smallComponents/MobileSearchSelection";
import MobileApprovalNotiBar from "../../smallComponents/MobileApprovalNotiBar";
import {
  SignInReducer,
  CategorySelectionReducer,
  ApprovalReducer
} from "../../../../../types/reducers";
import RequestDate from "../../smallComponents/RequestDate/RequestDate";
import useMobilePagenation from "../../../../hooks/mobile-hooks/use-mobile-pagenation";
import useHandleDataInApprovalWithRequest from "../../../../hooks/mobile-hooks/use-handle-data-in-approval-with-request";
import useIsLoading from "../../../../hooks/use-is-loading";
import useApprovalDialog from "../../../../hooks/mobile-hooks/mobile-approval-hooks/use-approval-dialog";
import { updateApprovalDoc } from "../../../../redux/sagas/restapi-calls/approvalCalls";
interface IProps {
  signInReducer: SignInReducer;
  approvalReducer: ApprovalReducer;
  categorySelectionReducer: CategorySelectionReducer;
  selectApprovalDoc: any;
  updateApprovalDoc: any;
}

const SearchBar = <P extends IProps>(props: P) => {
  const {
    selectedIndex,
    save,
    openDialog,
    handleSave,
    handleOpenDialog,
    wrappingOpenDialogWithIndex,
    handleSelectedIndex
  } = useApprovalDialog();
  const {
    searchType,
    searchWord,
    updateSearchWord,
    startDate,
    endDate,
    changeStartDate,
    changeEndDate,
    categoryId,
    updateCategoryId,
    requestResult,
    updateRequestResult,
    updateSearchType,
    setLastApproverId,
    dialogResult,
    openNoti,
    setOpenNoti
  } = searchStateManager();
  const { signInReducer, approvalReducer, categorySelectionReducer } = props;

  const { isLoading, handleIsLoading } = useIsLoading();
  const { data, searchData } = useHandleDataInApprovalWithRequest({
    type: "Approval",
    categorySelectionReducer,
    selectDoc: handleSelectedIndex,
    startDate,
    endDate,
    searchType,
    searchWord,
    categoryId,
    resultValue: requestResult,
    employeeId: signInReducer.employee_id,
    handleIsLoading
  });

  const { getDataWhenScrolling, handleOffset } = useMobilePagenation({
    searchData,
    isLoading
  });

  const updateApprovalData = React.useCallback(async () => {
    try {
      await updateApprovalDoc(
        signInReducer.employee_id,
        data[selectedIndex].approval_request_idx,
        approvalReducer.approvedOrRejected,
        approvalReducer.comment
      );
    } catch (error) {
      console.log(error);
    } finally {
      handleOffset(0);
      searchData(0);
    }
  }, [
    signInReducer.employee_id,
    data,
    selectedIndex,
    approvalReducer,
    searchData,
    handleOffset
  ]);

  useEffect(() => {
    if (selectedIndex !== -1 && !save) {
      getCurrentApprover(data[selectedIndex].approval_request_idx)
        .then(ret => {
          if (
            ret.status === 200 &&
            ret.data &&
            (ret.data[0].approver_id === signInReducer.employee_id ||
              ret.data[0].delegater_id === signInReducer.employee_id)
          ) {
            setLastApproverId(ret.data[0].approver_id);
            handleOpenDialog(true);
          } else {
            setOpenNoti(true);
            handleSelectedIndex(-1);
          }
        })
        .catch(e => {
          setOpenNoti(true);
          handleSelectedIndex(-1);
        });
    }
    if (selectedIndex !== -1 && approvalReducer && save) {
      updateApprovalData();
      handleSelectedIndex(-1);
      handleSave(false);
    }
  }, [
    data,
    selectedIndex,
    handleSelectedIndex,
    save,
    searchData,
    dialogResult,
    handleOpenDialog,
    setLastApproverId,
    setOpenNoti,
    handleSave,
    signInReducer.employee_id,
    approvalReducer,
    updateApprovalData
  ]);
  return (
    <RootDiv>
      <Container>
        <RequestDate
          onChange={changeStartDate}
          isStart={true}
          endDate=""
          startDate={approvalReducer.startDate}
          label="시작날짜"
        />
        <RequestDate
          onChange={changeEndDate}
          isStart={false}
          startDate=""
          endDate={approvalReducer.endDate}
          label="종료날짜"
        />
        <MobileCategorySelectionContainer
          isCategory={true}
          categoryId={categoryId}
          updateCategoryId={updateCategoryId}
          isRequestDialogPage={false}
        />
        <MobileCategorySelectionContainer
          isCategory={false}
          requestResult={requestResult}
          updateRequestResult={updateRequestResult}
          isRequestDialogPage={false}
        />
        <MobileSearchSelection
          updateSearchWord={updateSearchWord}
          updateSearchType={updateSearchType}
        />
      </Container>
      <MobileContentsContainer
        handleOpenDialog={wrappingOpenDialogWithIndex}
        pageType="Approval"
        getDataWhenScrolling={getDataWhenScrolling}
        data={data}
        isLoading={isLoading}
      />
      <BtnContainer>
        <Btn
          color="primary"
          aria-label="Add"
          onClick={() => {
            handleOffset(0);
            searchData(0);
          }}
        >
          <SearchIcon />
        </Btn>
      </BtnContainer>
      <MobileApprovalDialogContainer
        handleOpenDialog={wrappingOpenDialogWithIndex}
        openDialog={openDialog}
        data={data}
        selectedIndex={selectedIndex}
      />
      <MobileApprovalNotiBar openNoti={openNoti} setOpenNoti={setOpenNoti} />
    </RootDiv>
  );
};

export default SearchBar;
