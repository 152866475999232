import api from "../api";

const APPROVAL = `${api.api}/approval`;
const COUNT_SEARCH_APPROVAL_DOCS2 = `${APPROVAL}/count-search-approval-docs-v2`;
const SEARCH_APPROVAL_DOCS = `${APPROVAL}/searchapprovaldocs`;
const SET_APPROVAL_RESULT = `${APPROVAL}/setapprovalresult`;
const SEARCH_DELEGATE = `${APPROVAL}/searchdelegate`;
const GET_CURRENT_APPROVER = `${APPROVAL}/getcurrentapprover`;
const APPROVAL_DOWNLOAD_EXCEL = `${APPROVAL}/download-excel`;

const approvalApi = {
  countSearchApprovalDocsV2: COUNT_SEARCH_APPROVAL_DOCS2,
  searchApprovalDocs: SEARCH_APPROVAL_DOCS,
  setApprovalResult: SET_APPROVAL_RESULT,
  searchDelegate: SEARCH_DELEGATE,
  getCurrentApprover: GET_CURRENT_APPROVER,
  approvalDownloadExcel: APPROVAL_DOWNLOAD_EXCEL
};

export default approvalApi;
