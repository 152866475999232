import React from "react";
import { NavContainer, Item, SLink } from "./styled";
import items from "./items";
import handleLogout from "./navUtils/logout";
import { MAINVIEW_PATH } from "../../../constValues";
import { useHistory } from "react-router-dom";
import { SignInReducer } from "../../../../types/reducers";

const SHOW = 1;

interface IProps {
  pathName: string;
  cleanReducers: () => void;
  signInReducer: SignInReducer;
}

const Navbar = <P extends IProps>(props: P) => {
  const {
    pathName,
    cleanReducers,
    signInReducer: { menuList }
  } = props;
  const history = useHistory();

  return (
    <React.Fragment>
      {localStorage.token && (
        <NavContainer>
          <Item isPath={pathName === items.home.path}>
            <SLink to={items.home.path}>
              <i className={items.home.icon} />
            </SLink>
          </Item>
          {menuList[0]?.web_approval_flag === SHOW && (
            <React.Fragment>
              <Item isPath={pathName === items.approval.path}>
                <SLink to={items.approval.path}>
                  <i className={items.approval.icon} />
                </SLink>
              </Item>
              <Item isPath={pathName === items.request.path}>
                <SLink to={items.request.path}>
                  <i className={items.request.icon} />
                </SLink>
              </Item>
            </React.Fragment>
          )}
          <Item
            isPath={pathName === items.des.path}
            onClick={() => history.push(`${MAINVIEW_PATH}home`)}
          >
            <SLink to={pathName}>
              <i className={items.des.icon} />
            </SLink>
          </Item>
          <Item isPath={pathName === items.settings.path}>
            <SLink to={items.settings.path}>
              <i className={items.settings.icon} />
            </SLink>
          </Item>
          <Item
            isPath={pathName === items.logOut.path}
            onClick={() => {
              handleLogout(cleanReducers);
            }}
          >
            <SLink to={items.logOut.redirect}>
              <i className={items.logOut.icon} />
            </SLink>
          </Item>
        </NavContainer>
      )}
    </React.Fragment>
  );
};

export default Navbar;
