import MobileCategorySelectionContainer from "../../../../moContainer/main-view/mobile-category-selection-container";
import MobileContentsContainer from "../../../../moContainer/main-view/MobileContentsContainer.js";
import MobileApprovalNotiBar from "../MobileApprovalNotiBar";
import * as React from "react";
import {
  RootDiv,
  Container,
  Btn,
  BtnContainer
} from "../../MobileApprovalView/mobile-approval-searchbar/styled";
import SearchIcon from "@material-ui/icons/Search";
import EditIcon from "@material-ui/icons/Edit";
import RequestDate from "../RequestDate/RequestDate";
import MobileRequestSearchManager from "./MobileRequestSearchStateManager";
import MobileRequestDialogContainer from "../../../../moContainer/main-view/MobileRequestDialogContainer";
import {
  CategorySelectionReducer,
  RequestReducer,
  SignInReducer
} from "../../../../../types/reducers";
import useMobilePagenation from "../../../../hooks/mobile-hooks/use-mobile-pagenation";
import useIsLoading from "../../../../hooks/use-is-loading";
import useHandleDataInApprovalWithRequest from "../../../../hooks/mobile-hooks/use-handle-data-in-approval-with-request";

interface IProps {
  signInReducer: SignInReducer;
  requestReducer: RequestReducer;
  categorySelectionReducer: CategorySelectionReducer;
  selectRequestDoc: any;
}

const MobileRequestSearchBar = <P extends IProps>(props: P) => {
  const { isLoading, handleIsLoading } = useIsLoading();
  const {
    startDate,
    endDate,
    changeStartDate,
    changeEndDate,
    categoryId,
    updateCategoryId,
    requestResult,
    updateRequestResult,
    openDialog,
    openNoti,
    setOpenNoti,
    handleOpenDialog
  } = MobileRequestSearchManager();
  const {
    signInReducer,
    requestReducer,
    selectRequestDoc,
    categorySelectionReducer
  } = props;

  const { data, searchData } = useHandleDataInApprovalWithRequest({
    type: "Request",
    categorySelectionReducer,
    selectDoc: selectRequestDoc,
    startDate,
    endDate,
    categoryId,
    resultValue: requestResult,
    employeeId: signInReducer.employee_id,
    handleIsLoading
  });

  const { getDataWhenScrolling, handleOffset } = useMobilePagenation({
    searchData,
    isLoading
  });

  return (
    <RootDiv>
      <Container>
        <RequestDate
          onChange={changeStartDate}
          isStart={true}
          startDate={requestReducer.startDate}
          endDate=""
          label="시작날짜"
        />
        <RequestDate
          onChange={changeEndDate}
          isStart={false}
          startDate=""
          endDate={requestReducer.endDate}
          label="종료날짜"
        />
        <MobileCategorySelectionContainer
          isCategory={true}
          categoryId={categoryId}
          updateCategoryId={updateCategoryId}
          isRequestDialogPage={false}
        />
        <MobileCategorySelectionContainer
          isCategory={false}
          isRequestDialogPage={false}
          requestResult={requestResult}
          updateRequestResult={updateRequestResult}
        />
      </Container>
      <MobileContentsContainer
        pageType="Request"
        getDataWhenScrolling={getDataWhenScrolling}
        data={data}
        isLoading={isLoading}
      />
      <BtnContainer>
        <Btn
          onClick={() => {
            handleOffset(0);
            searchData(0);
          }}
          color="primary"
          aria-label="add"
        >
          <SearchIcon />
        </Btn>
        <Btn onClick={handleOpenDialog} color="primary" aria-label="edit">
          <EditIcon />
        </Btn>
      </BtnContainer>
      <MobileRequestDialogContainer
        departmentId={signInReducer.department_id}
        approvers={
          signInReducer.approverInfo
            ? signInReducer.approverInfo.map((approver: any) => {
                approver["key"] = approver.employee_id;
                return approver;
              })
            : []
        }
        openDialog={openDialog}
        handleOpenDialog={handleOpenDialog}
      />
      <MobileApprovalNotiBar openNoti={openNoti} setOpenNoti={setOpenNoti} />
    </RootDiv>
  );
};

export default MobileRequestSearchBar;
