import React from "react";
import stateManager from "./stateManager";
import MobileCategorySelectionContainer from "../../../../moContainer/main-view/mobile-category-selection-container";
import uiString from "./string.json";
import {
  Container,
  BackBtnContainer,
  GobackBtn,
  NextBtn,
  BtnContainer,
  Steppers,
  MainContainer,
  ReasonForRequest
} from "./styled";
import {
  titleCreator,
  dateCreator,
  timeCreator,
  _handleKeyPress,
  _getDateFormatter,
  _getTimeFormatter
} from "./utils";
import MobileSearchApprover from "../MobileSearchApprover";
import MobileRequestNotiBar from "../MobileRequestNotiBar";
import MobileRequestAlert from "../MobileRequestAlert/MobileRequestAlert";
import useDate from "../../../../hooks/detail-view-hook/place-free-management-hook/use-date";
const MobileRequestDialogView = props => {
  const {
    openDialog,
    handleOpenDialog,
    departmentId,
    approvers,
    makeNewRequest,
    signInReducer
  } = props;
  const {
    activeStep,
    handleNextActiveStep,
    handleBackActiveStep,
    categoryId,
    updateCategoryId,
    startTime,
    handleStartTime,
    endTime,
    handleEndTime,
    comment,
    handleComment,
    alterStartDate,
    handleAlterStartDate,
    alterEndDate,
    handleAlterEndDate,
    alterStartTime,
    handleAlterStartTime,
    alterEndTime,
    handleAlterEndTime,
    nameOfVoter,
    handleNameOfVoter,
    searchResult,
    handleSearchResult,
    selectedVoter,
    handleAddVoter,
    handleDeleteVoter,
    setSelectedVoter,
    handleSelectedVoterPosition,
    handleInitial,
    openNoti,
    handleOpenNoti,
    alertOpen,
    handleAlertOpen,
    successSave,
    setSuccessSave,
    setActiveStep
  } = stateManager();
  const { startDate, endDate, handleDate } = useDate();
  const handleSave = React.useCallback(() => {
    makeNewRequest({
      employeeId: signInReducer.employee_id,
      overtimeCategoryId: categoryId,
      startDayOffDate: _getDateFormatter(startDate),
      endDayOffDate: _getDateFormatter(endDate),
      startDayOffTime: _getTimeFormatter(startTime),
      endDayOffTime: _getTimeFormatter(endTime),
      comment: comment,
      startAlterWorkDate:
        categoryId === uiString.dialog.category.alternativeWorkId
          ? _getDateFormatter(alterStartDate)
          : "",
      endAlterWorkDate:
        categoryId === uiString.dialog.category.alternativeWorkId
          ? _getDateFormatter(alterEndDate)
          : "",
      startAlterWorkTime:
        categoryId === uiString.dialog.category.alternativeWorkId
          ? _getTimeFormatter(alterStartTime)
          : "",
      endAlterWorkTime:
        categoryId === uiString.dialog.category.alternativeWorkId
          ? _getTimeFormatter(alterEndTime)
          : "",
      approverId: selectedVoter.map(voter => voter.employee_id)
    });
    handleOpenDialog();
    handleInitial();
    handleAlertOpen();
    setSuccessSave(true);
    handleOpenNoti();
  }, [
    alterEndDate,
    alterEndTime,
    alterStartDate,
    alterStartTime,
    categoryId,
    comment,
    endDate,
    endTime,
    handleAlertOpen,
    handleInitial,
    handleOpenDialog,
    handleOpenNoti,
    makeNewRequest,
    selectedVoter,
    setSuccessSave,
    signInReducer.employee_id,
    startDate,
    startTime
  ]);

  return (
    <>
      <Container openDialog={openDialog}>
        <BackBtnContainer
          onClick={() => {
            handleOpenDialog();
            setActiveStep(0);
            handleComment();
          }}
        >
          <GobackBtn />
          <span>{uiString.dialog.title.cancle}</span>
        </BackBtnContainer>
        <MainContainer>
          {titleCreator(uiString, activeStep, categoryId)}
          {activeStep === 0 && (
            <MobileCategorySelectionContainer
              isRequestDialogPage={true}
              isCategory={true}
              categoryId={categoryId}
              updateCategoryId={updateCategoryId}
            />
          )}
          {activeStep === 0 &&
            dateCreator(
              startDate,
              endDate,
              handleDate("start"),
              handleDate("end")
            )}
          {categoryId === uiString.dialog.category.alternativeWorkId &&
            activeStep === 1 &&
            dateCreator(
              alterStartDate,
              alterEndDate,
              handleAlterStartDate,
              handleAlterEndDate
            )}
          {activeStep === 0 &&
            timeCreator(
              startTime,
              endTime,
              handleStartTime,
              handleEndTime,
              activeStep
            )}
          {categoryId === uiString.dialog.category.alternativeWorkId &&
            activeStep === 1 &&
            timeCreator(
              alterStartTime,
              alterEndTime,
              handleAlterStartTime,
              handleAlterEndTime,
              activeStep,
              categoryId
            )}
          {categoryId === uiString.dialog.category.alternativeWorkId
            ? activeStep === 1 && (
                <ReasonForRequest
                  id="textarea"
                  value={comment}
                  label={uiString.dialog.reasonForRequest}
                  placeholder={uiString.dialog.reasonForRequestPlaceholder}
                  multiline
                  onChange={handleComment}
                  margin="normal"
                />
              )
            : activeStep === 0 && (
                <ReasonForRequest
                  id="textarea"
                  value={comment}
                  label={uiString.dialog.reasonForRequest}
                  placeholder={uiString.dialog.reasonForRequestPlaceholder}
                  multiline
                  onChange={handleComment}
                  margin="normal"
                />
              )}
          {categoryId === uiString.dialog.category.alternativeWorkId
            ? activeStep === 2 &&
              comment && (
                <MobileSearchApprover
                  handleAlertOpen={handleAlertOpen}
                  handleSelectedVoterPosition={handleSelectedVoterPosition}
                  setSelectedVoter={setSelectedVoter}
                  approvers={approvers}
                  handleDeleteVoter={handleDeleteVoter}
                  selectedVoter={selectedVoter}
                  handleAddVoter={handleAddVoter}
                  _handleKeyPress={_handleKeyPress}
                  departmentId={departmentId}
                  handleSearchResult={handleSearchResult}
                  handleNameOfVoter={handleNameOfVoter}
                  nameOfVoter={nameOfVoter}
                  uiString={uiString}
                  searchResult={searchResult}
                  handleOpenNoti={handleOpenNoti}
                  setActiveStep={setActiveStep}
                />
              )
            : activeStep === 1 &&
              comment && (
                <MobileSearchApprover
                  handleAlertOpen={handleAlertOpen}
                  handleSelectedVoterPosition={handleSelectedVoterPosition}
                  setSelectedVoter={setSelectedVoter}
                  approvers={approvers}
                  handleDeleteVoter={handleDeleteVoter}
                  selectedVoter={selectedVoter}
                  handleAddVoter={handleAddVoter}
                  _handleKeyPress={_handleKeyPress}
                  departmentId={departmentId}
                  handleSearchResult={handleSearchResult}
                  handleNameOfVoter={handleNameOfVoter}
                  nameOfVoter={nameOfVoter}
                  uiString={uiString}
                  searchResult={searchResult}
                  handleOpenNoti={handleOpenNoti}
                  setActiveStep={setActiveStep}
                />
              )}
        </MainContainer>
        <Steppers
          variant="dots"
          steps={
            categoryId === uiString.dialog.category.alternativeWorkId ? 3 : 2
          }
          activeStep={activeStep}
          nextButton={
            <BtnContainer
              onClick={handleNextActiveStep(
                categoryId === uiString.dialog.category.alternativeWorkId,
                comment
              )}
              disabled={
                categoryId === uiString.dialog.category.alternativeWorkId
                  ? activeStep === 2
                  : activeStep === 1
              }
              size="small"
            >
              Next
              <NextBtn />
            </BtnContainer>
          }
          backButton={
            <BtnContainer
              onClick={handleBackActiveStep}
              disabled={activeStep === 0}
              size="small"
            >
              <GobackBtn /> Back
            </BtnContainer>
          }
        />
        <MobileRequestAlert
          alertOpen={alertOpen}
          handleAlertOpen={handleAlertOpen}
          handleSave={handleSave}
        />
      </Container>
      <MobileRequestNotiBar
        successSave={successSave}
        isComment={!!comment}
        uiString={uiString}
        openNoti={openNoti}
        setOpenNoti={handleOpenNoti}
        nameOfVoter={nameOfVoter}
      />
    </>
  );
};

export default MobileRequestDialogView;
