import axios from "axios";
import { WEB_SERVER_ADDR } from "../constValues";
import Auth from "../Auth/Auth";
import jwt from "jsonwebtoken";
import settings from "../config/settings";
import dotenv from "dotenv";
import routes from "../routes";

dotenv.config();

const API = () =>
  axios.create({
    baseURL: `${WEB_SERVER_ADDR}`,
    headers: {
      token: Auth.getUserToken(),
      Pragma: "no-cache"
    }
  });

const KAKAO_API = () =>
  axios.create({
    baseURL: routes.apiRoutes.kakao.base,
    headers: {
      Authorization: process.env.REACT_APP_KAKAO_ADMIN_API_KEY
    }
  });

export const getAdressName = (lon, lat) =>
  KAKAO_API().get(
    `/geo/coord2address.json?x=${String(lon)}&y=${String(
      lat
    )}&input_coord=WGS84`
  );
export const getChartData = (date_type, info_type, users, employee_ids, date) =>
  API().post(routes.apiRoutes.chart.chart, {
    date_type,
    info_type,
    users,
    employee_ids,
    date
  });

export const getTodayTotalWorkhours = employee_ids =>
  API().get(routes.apiRoutes.workHour.getWorkHourRecordsForToday, {
    employee_ids,
    headers: {
      Pragma: "no-cache"
    }
  });

export const getWorkTypes = () =>
  API().get(routes.apiRoutes.workHour.workTypes);

export const getUserInfo = (employee_id, department_id) =>
  API().post(routes.apiRoutes.workHour.employeeResource, {
    employee_id,
    department_id
  });

export const getTreeData = employeeid =>
  API().get(routes.apiRoutes.workHour.departmentsUser(employeeid));

export const recordWorkHour = data => {
  return API().post(routes.apiRoutes.workHour.recordWorkHour, data);
};

export const getLastWorkHour = employeeid =>
  API().get(routes.apiRoutes.workHour.lastWorkHourUser(employeeid));

export const getVoters = (departmentId, searchName) =>
  API().post(routes.apiRoutes.approval.searchDelegate, {
    departmentId,
    name: searchName
  });

export const searchEmployees = searchName =>
  API().post(routes.apiRoutes.settings.searchEmployee, {
    name: searchName
  });

export const searchDepartment = departmentName =>
  API().post(routes.apiRoutes.settings.searchDepartment, {
    name: departmentName
  });

export const setUserEmail = (employeeId, email) => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/email`;

  return axios
    .post(
      restApiUri,
      {
        employeeId,
        email
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const setUserCCList = (employeeId, cclist) => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/cclist`;

  return axios
    .post(
      restApiUri,
      {
        employeeId,
        cclist
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const getApprover = employeeId => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/request/approver/${employeeId}`;

  return axios
    .get(restApiUri, {
      headers: {
        token: Auth.getUserToken()
      }
    })
    .then(response => {
      return response;
    });
};

export const uploadUserProfile = async (employeeId, selectedFile, fileName) => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/upload/profile/${employeeId}`;
  const formData = new FormData();
  formData.append("userProfile", selectedFile, fileName);

  try {
    const ret = await axios.post(restApiUri, formData, {
      headers: {
        token: Auth.getUserToken()
      }
    });
    if (ret.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const addDelegater = async (employeeId, delegaters) => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/delegater`;

  const hasDelegater = delegaters.length >= 1;
  //   if (delegaters.length < 1) return;
  let delegaterInfo = { delegaterId: null, startDate: null, endDate: null };
  if (hasDelegater) {
    const startDate = delegaters[0].startDate;
    const endDate = delegaters[0].endDate;
    delegaterInfo["delegaterId"] = delegaters[0].employee_id;
    delegaterInfo["startDate"] = startDate.format("YYYY-MM-DD");
    delegaterInfo["endDate"] = endDate.format("YYYY-MM-DD");
  }

  try {
    const result = await axios.post(
      restApiUri,
      {
        employeeId,
        delegaterId: delegaterInfo.delegaterId,
        startDate: delegaterInfo.startDate,
        endDate: delegaterInfo.endDate
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    );

    if (result.status === 200) {
      return true;
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};

export const setSmtpInfo = (
  employeeId,
  smtpAddress,
  smtpPort,
  alertEmail,
  alertEmailPassword
) => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/smtp`;

  let secretToken = jwt.sign(
    {
      employeeId,
      smtpAddress,
      smtpPort,
      alertEmail,
      alertEmailPassword
    },
    settings.secret
  );

  return axios
    .post(
      restApiUri,
      {
        secretToken
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const getSmtpInfo = employeeId => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/smtp`;

  return axios
    .get(restApiUri, {
      headers: {
        token: Auth.getUserToken()
      }
    })
    .then(response => {
      return response;
    });
};

export const getQueryList = (publish = false) => {
  let restApiUri = publish
    ? `${WEB_SERVER_ADDR}/api/statistics/userquerylist`
    : `${WEB_SERVER_ADDR}/api/statistics/querylist`;

  return axios
    .get(restApiUri, {
      headers: {
        token: Auth.getUserToken()
      }
    })
    .then(response => {
      return response;
    });
};

export const saveQueryToServer = queryContents => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/statistics/savequery`;

  return axios
    .post(
      restApiUri,
      {
        queryContents
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const executeQuery = queryContents => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/statistics/exequery`;
  return axios
    .post(
      restApiUri,
      {
        queryContents
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const downloadCsv = queryContents => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/statistics/downloadcsv`;

  return axios
    .post(
      restApiUri,
      {
        queryContents
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const delQueryFromServer = index => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/statistics/delquery`;

  return axios
    .post(
      restApiUri,
      {
        index
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const publishQuery = (index, publish) => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/statistics/publishquery`;

  return axios
    .post(
      restApiUri,
      {
        index,
        publish
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const getCurrentApprover = approvalIdx => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/approval/getcurrentapprover`;

  return axios
    .post(
      restApiUri,
      { approvalIdx },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const getWorkingTemplatePolicy = () => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/workingtemplate`;

  return axios
    .get(restApiUri, {
      headers: {
        token: Auth.getUserToken()
      }
    })
    .then(response => {
      return response;
    });
};

export const setWorkingTemplatePolicy = (employeeId, workTempIdx) => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/workingtemplate`;

  return axios
    .post(
      restApiUri,
      {
        employeeId,
        workTempIdx
      },
      {
        headers: {
          token: Auth.getUserToken()
        }
      }
    )
    .then(response => {
      return response;
    });
};

export const getCustomStatisticsField = () => {
  let restApiUri = `${WEB_SERVER_ADDR}/api/settings/customstatisticsfield`;

  return axios
    .get(restApiUri, {
      headers: {
        token: Auth.getUserToken()
      }
    })
    .then(response => {
      return response;
    });
};
