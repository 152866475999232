import styled from "styled-components";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";
import NativeSelect from "@material-ui/core/NativeSelect";

export const CategorySelect = styled(NativeSelect)`
  && {
    padding-left: 10px;
  }
`;

export const Label = styled(InputLabel)`
  && {
    color: #8d2222;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const SearchField = styled(TextField)``;

export const Container = styled.div``;

export const SearchContainer = styled.div`
  display: flex;
  border-bottom: 1px solid black;
`;
