import styled from "styled-components";
import Fab from "@material-ui/core/Fab";

export const RootDiv = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 10px;
`;

export const BtnContainer = styled.div`
  display: flex;
  position: absolute;
  bottom: 50px;
  right: 0;
`;

export const Btn = styled(Fab)`
  && {
    margin: 10px 10px 10px 0;
    align-self: flex-end;
    background-color: #40a6fb;
    color: white;
    font-weight: bold;
  }
`;
