import styled from "styled-components";
import Button from "@material-ui/core/Button";

export const Btn = styled(Button)`
  && {
    background-color: #e65122;
    color: white;
    :hover {
      background-color: #2c3e50;
    }
  }
`;
