import styled from "styled-components";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "@material-ui/core/TextField";

export const ApprovalRemarksContainer = styled.div`
  display: flex;
  max-height: 200px;
  flex-direction: column;
  border-bottom: 1px solid black;
  overflow: scroll;
`;

export const ApprovalRemarksLabel = styled(InputLabel)`
  && {
    color: #8d2222;
    font-size: 12px;
    font-weight: bold;
  }
`;

export const ApprovalRemarksField = styled(TextField)`
  && {
    padding-left: 10px;
    word-wrap: break-word;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  display: ${props => (props.openDialog ? "flex" : "none")};
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  justify-content: center;
  align-items: center;
`;

export const DialogContainer = styled.div`
  width: 80%;
  border-radius: 20px;
  border: 2px solid #3d81e7;
  background-color: white;
  padding: 10px;
`;

export const ApplyReasonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ApplyTitle = styled.h1`
  font-weight: bold;
  font-size: 18px;
  align-self: flex-start;
`;

export const ApplyReasonContents = styled.span`
  background-color: #f7f3ee;
  border-radius: 15px 15px 15px 0;
  padding: 10px;
  width: 80%;
  height: 50%;
  border: 1px solid #3d81e7;
`;

export const ApprovalListContainer = styled.div``;

export const ApprovalListTitle = styled.h1`
  font-weight: bold;
  font-size: 18px;
  align-self: flex-start;
`;

export const ApprovalSwitch = styled(Switch)``;

export const Btn = styled.button`
  border: none;
  outline: none;
  background-color: #40a6fb;
  color: white;
  font-weight: bold;
  padding: 5px 25px 5px 25px;
  border-radius: 15px;
  margin: 10px;
  :active {
    background-color: #2c3e50;
    transform: scale(0.95, 0.95);
  }
`;

export const Label = styled(FormControlLabel)``;
