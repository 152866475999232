import styled from "styled-components";
import TextField from "@material-ui/core/TextField";

export const Date = styled(TextField)`
  && {
    input {
      padding: 5px 10px 2px 10px;
    }
    border-bottom: 1px solid black;
    :not(:last-child) {
      margin-bottom: 10px;
    }
    label {
      color: #8d2222;
      font-weight: bold;
    }
    [type="date"]::-webkit-clear-button {
      display: none;
    }
    [type="date"]::-webkit-inner-spin-button {
      display: none;
    }
    [type="date"]::-webkit-calendar-picker-indicator {
      color: rgba(0, 0, 0, 0);
      opacity: 1;
      display: block;
      background: url(https://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/calendar_2.png)
        no-repeat;
      width: 20px;
      height: 20px;
      border-width: thin;
    }
  }
`;
