import routes from "../../../routes";
import API from "../../api";

type EmployeeId = {
  userId: string;
};

const getCurrentTimeBox = (params: EmployeeId) =>
  API().get(`${routes.apiRoutes.dashboard.personalApi.currentTimeBox}`, {
    params
  });

const getMyWorkStatusBox = (params: EmployeeId) =>
  API().get(`${routes.apiRoutes.dashboard.personalApi.myWorkStatusBox}`, {
    params
  });

const getTodayWorkingInformationBox = (params: EmployeeId) =>
  API().get(
    `${routes.apiRoutes.dashboard.personalApi.todayWorkingInformationBox}`,
    {
      params
    }
  );

const getThisWeekWorkingInformationBox = (params: EmployeeId) =>
  API().get(
    `${routes.apiRoutes.dashboard.personalApi.thisWeekWorkingInformationBox}`,
    {
      params
    }
  );

const getMonthlyAbsenteeismTardiness = (params: EmployeeId) =>
  API().get(
    routes.apiRoutes.dashboard.personalApi.monthlyAbsenteeismTardiness,
    { params }
  );

const getVacationStatus = (params: EmployeeId) =>
  API().get(routes.apiRoutes.dashboard.personalApi.vacationStatus, { params });

const monthlyWorkingStatus = (params: EmployeeId) =>
  API().get(routes.apiRoutes.dashboard.personalApi.monthlyWorkingStatus, {
    params
  });

const personalApi = {
  getCurrentTimeBox,
  getMyWorkStatusBox,
  getTodayWorkingInformationBox,
  getThisWeekWorkingInformationBox,
  getMonthlyAbsenteeismTardiness,
  getVacationStatus,
  monthlyWorkingStatus
};

export default personalApi;
